.row-data {
  border: 0.5px solid #999;
  padding: 10px;
}

.question-description {
  /* border: 0.5px solid #999; */
  padding: 7px;
  /* margin: 20px; */
}
textarea:focus { 
  outline: none !important;
  border: 0;
}
@media screen and (min-width: 1000px) {
  .question-list {
    text-align: right;
  }

  .row-data-result {
    border: 0.5px solid #999;
    padding: 10px;
  }

  .row-data-result-phone {
    display: none;
  }

  .question-height {
    height: 70vh;
  }
}

@media screen and (max-width: 1000px) {
  .question-list {
    text-align: center;
  }

  .previous-question {
    text-align: center;
  }

  .row-data-result {
    display: none
  }

  .row-data-result-phone {
    border: 3px solid #000;
    padding: 3px 10px;
    margin: 5px 0;
  }

  .row-data-result-phone2 {
    border-bottom: 0.5px solid #999;
    padding: 10px;
  }
}

audio::-webkit-media-controls-timeline-container {
  display: none !important;
}

audio::-webkit-media-controls-current-time-display {
  display: none !important;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

audio::-webkit-media-controls-timeline {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider-container {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

audio::-webkit-media-controls-seek-back-button {
  display: none !important;
}

audio::-webkit-media-controls-seek-forward-button {
  display: none !important;
}

audio::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

audio::-webkit-media-controls-rewind-button {
  display: none !important;
}

audio::-webkit-media-controls-return-to-realtime-button {
  display: none !important;
}

audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}


audio {
  width: 100px;
  height: 54px;
}

.active-bar {
  padding: 10px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #000;
  font-weight: bold;
}

.passive-bar {
  padding: 10px;
  background-color: rgb(119, 118, 118);
  text-align: center;
  color: #fff;
  border: 1px solid #000;
  font-weight: bold;
  cursor: pointer;
}

.home-box{
  background-image: linear-gradient(80deg,#202020, #3d3d3d);

}
.home-box:hover {
  transform: scale(1.04);


}