.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.hide {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (min-width: 600px) {
  .heading-logo-style {
    display: flex;
    justify-content: center;
    /* padding: 15px; */
  }

  .timer-value {
    text-align: right;
  }

  .active-sidebar-2 {
    border: 2px solid #156cf2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    font-weight: bold;
    color: #efd85a;
    padding: 15px 0;
  }

  .passive-sidebar-2 {
    border: 0.5px solid #156cf2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 15px 0;
  }
}

@media screen and (max-width: 600px) {
  .heading-logo-style {
    display: flex;
    justify-content: center;
    /* padding: 50px 0 10px; */
  }

  .question-solved {
    text-align: center;
  }

  .timer-value {
    text-align: center;
  }

  .active-sidebar-2 {
    display: none;
  }

  .passive-sidebar-2 {
    display: none;
  }
}

.active-sidebar {
  border: 2px solid #156cf2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #156cf2;
  font-weight: bold;
  color: #fff;
  padding: 15px 0;
}

.passive-sidebar {
  border: 0.5px solid #156cf2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-weight: bold;
  padding: 15px 0;
}

.answer-option-style {
  padding: 10px 10px;
  cursor: pointer;
}

.active-option2 {
  font-weight: bold;
  padding: 0px 10px;
}

.wrong-option-result {
  font-weight: bold;
  padding: 0px 10px;
  background-color: #b21e1e;
  color: #fff;

}

.correct-option-result {
  font-weight: bold;
  padding: 0px 10px;
  background-color: #66fc03;
}

.wrong-option-result-2 {
  font-weight: bold;
  padding: 10px 10px;
  background-color: #b21e1e;
  color: #fff;
  text-align: center;
}

.correct-option-result-2 {
  font-weight: bold;
  padding: 10px 10px;
  background-color: #66fc03;
  text-align: center;
}

.passive-option2 {
  padding: 0px 10px;
}

.active-option {
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-weight: bold;
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 10px;
}


.active-option-3 {
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 10px;
}
.passive-option-3 {
  border: 0.5px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 10px;
}
.passive-option {
  border: 0.5px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 10px;
}


.font-size-12 {
  font-size: 12px;
}